<template>
  <div class="specList">
    <ul class="listBox">
      <li
        class="item"
        v-for="(item, index) in listClassModels"
        v-show="item.Checked"
        :key="item.PCModelId"
      >
        <p class="title">{{ item.ModelName }}</p>
        <van-row class="sizeBox">
          <van-col
            v-for="newItem in item.SonClassModels"
            v-show="newItem.Checked"
            :key="newItem.PCModelId"
            :class="[
              'size',
              newItem.PCModelId == pCModelIdList[index] ? 'sizeAction' : '',
            ]"
            @click="changeSpec(newItem, newItem.PCModelId, index)"
            >{{ newItem.ModelName }}</van-col
          >
        </van-row>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "SpecList",
  props: ["listClassModels", "productModelPrice"],
  data() {
    return {};
  },
  computed: {
    // 规格名称列表
    pCModelIdList() {
      //console.log(this.productModelPrice);
      return this.productModelPrice.PCModelId.split("|");
    },
  },
  methods: {
    // 选择规格item为规格对象,pCModelId为规格id，index所属规格下标
    changeSpec(item, pCModelId, index) {
      this.$emit("changeSpec", item, pCModelId, index);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.specList {
  width: 92%;
  margin: 0 auto;
  .listBox {
    list-style: none;
    margin: 0;
    padding: 0;
    .item {
      margin: 0;
      padding: 15px;
      margin-top: 15px;
      @include publicBoxRadio;
      background-color: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      .title {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        font-size: 16px;
        color: #000000;
      }
      .sizeBox {
        margin-left: 13px;
        .size {
          margin: 13px 0;
          text-align: center;
          //padding: 16px 0;
          padding: 10px;
          min-width: 100px;
          border-radius: 50px;
          border: 1px solid #a7a7a7;
          margin-right: 26px;
          color: #a7a7a7;
          font-size: 14px;
        }
        .sizeAction {
          border: 1px solid #01aaa3;
          color: #ffffff;
          background-color: #01aaa3;
        }
      }
    }
  }
}
</style>
