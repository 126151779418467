<template>
  <div class="productInfo">
    <van-row type="flex" align="center" class="productTop">
      <van-col class="productImg">
        <van-image
          class="productIcon"
          :src="
            detailData.ProductPhoto && imgUrlFilter(detailData.ProductPhoto)
          "
          @click="imagePreview(imgUrlFilter(detailData.ProductPhoto))"
        >
          <template v-slot:error>
            <img class="ImgIcon" src="~assets/img/other/empty.gif" />
          </template>
        </van-image>
      </van-col>
      <van-col class="infoLeftBox">
        <section class="infoLeft">
          <div class="infoLeftTitle">
            <div class="infoLeftTitleName">{{ $t.specification }}：</div>
            <div class="infoLeftTitleNumber">{{ $t.cost }}：</div>
            <div class="infoLeftTitleNumber">{{ $t.inventory }}：</div>
          </div>
          <div class="infoLeftItme">
            <div class="infoLeftItmeName">
              <span
                v-show="guiGeString"
                v-for="(item, index) in guiGeString"
                :key="index"
                >{{ index !== 0 ? " / " : "" }}{{ item.ModelName }}</span
              >
            </div>
            <div class="infoLeftItmeNumber">
              {{ productModelPrice && productModelPrice.CustomerCurrentPrice }}
            </div>
            <div class="infoLeftItmeNumber">
              {{ productModelPrice && productModelPrice.Inventory }}
            </div>
          </div>
        </section>
      </van-col>
    </van-row>
    <!-- 设置价格 -->
    <van-row
      class="setPriceBox"
      type="flex"
      align="center"
      justify="space-between"
    >
      <van-col class="setPriceBoxLeft">
        <p class="setPriceBoxLeftTitle">{{ $t.updatePrice }}</p>
        <p class="setPriceBoxLeftName">({{ $t.sellingPrice }})</p>
      </van-col>
      <van-col class="setPriceBoxRight">
        <button class="setPriceBoxRightBt" @click="editing">
          {{ $t.msetKeyValue }}
        </button>
      </van-col>
    </van-row>
    <!-- 售价 -->
    <van-row class="sellingPrice" type="flex" align="center">
      <van-col class="sellingPriceLeft">{{ $t.sellingPrice }}</van-col>
      <van-col class="sellingPriceRight">
        <section class="sellingPriceRightBox">
          <p class="sellingPriceRightUnit">$</p>
          <!-- <van-field
            v-model="newCurrentPrice"
            v-on:input="changeCurrentPrice"
            placeholder="请输入用户名"
            class="sellingPriceRightInput"
            error-message="手机号格式错误"
          />-->
          <input
            class="sellingPriceRightInput"
            v-model="newCurrentPrice"
            v-on:input="changeCurrentPrice"
          />
        </section>
      </van-col>
    </van-row>
    <!-- 售价 -->
    <van-row class="sellingPrice" type="flex" align="center">
      <van-col class="sellingPriceLeft">{{ $t.profit }}</van-col>
      <van-col class="sellingPriceRight">
        <p class="sellingPriceRightNum">
          $ {{ subtr(currentPrice, productModelPrice.CustomerCurrentPrice) }}
        </p>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col, Field, Image } from "vant";
Vue.use(Row)
  .use(Col)
  .use(Field)
  .use(Image);
export default {
  name: "ProductInfo",
  props: ["detailData", "productModelPrice", "currentPrice", "listClassModels"],
  data() {
    return {
      setPrice: 400,
      guiGeString: undefined,
      newCurrentPrice: this.currentPrice,
    };
  },
  computed: {},
  watch: {
    // 当前使用规格
    productModelPrice() {
      let list = this.productModelPrice.PCModelId.split("|");
      this.guiGeString = this.listClassModels.map((item, index) => {
        let model = item.SonClassModels.filter((newItem) => {
          return newItem.PCModelId == list[index];
        });
        return model[0];
      });
      //console.log(this.guiGeString);
    },
    currentPrice() {
      this.newCurrentPrice = this.currentPrice;
    },
  },
  mounted() {},
  methods: {
    editing() {
      this.$emit("editing");
    },
    imagePreview(image) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.picturePreview, {
        pictureList: [image],
      });
    },
    imgUrlFilter(value) {
      //console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    // 精确减法
    subtr(arg1, arg2) {
      let r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      //last modify by deeka
      //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    // 修改价格
    changeCurrentPrice() {
      //console.log(this.newCurrentPrice);
      this.$emit("changeCurrentPrice", this.newCurrentPrice);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.productInfo {
  width: 92%;
  margin: 0 auto;
  @include publicBoxRadio;
  padding: 15px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 15px;
  .productTop {
    width: 100%;
    .productImg {
      width: 70px;
      height: 70px;
      margin-right:15px;
      .productIcon {
        width: 100%;
        height: 100%;
        overflow: hidden;
        ::v-deep .van-image__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        ::v-deep .van-image__error {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .infoLeftBox {
      flex: 1;
      -webkit-flex: 1;
      .infoLeft {
        @include publicFlex;
        .infoLeftTitle {
          width: 30%;
          .infoLeftTitleName {
            color: #a7a7a7;
            font-size: 14px;
            text-align: center;
            height: 50px;
          }
          .infoLeftTitleNumber {
            color: #a7a7a7;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            margin-bottom:10px;
          }
        }
        .infoLeftItme {
          width: 70%;
          .infoLeftItmeName {
            color: #000000;
            font-size: 14px;
            height: 50px;
            word-break: break-all;
          }
          .infoLeftItmeNumber {
            color: #000000;
            font-size: 14px;
            line-height: 20px;
            height: 20px;
            word-break: break-all;
            margin-bottom:10px;
          }
        }
      }
    }
  }
  .setPriceBox {
    padding: 20px 0;
    .setPriceBoxLeft {
      .setPriceBoxLeftTitle {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #000000;
      }
      .setPriceBoxLeftName {
        margin: 0;
        padding-top: 5px;
        color: #a7a7a7;
        font-size: 14px;
      }
    }
    .setPriceBoxRight {
      text-align: right;
      .setPriceBoxRightBt {
        border: none;
        background-color: #01aaa3;
        padding: 10px 0;
        border-radius: 50px;
        color: #ffffff;
        width: 150px;
        font-size: 14px;
      }
    }
  }
  .sellingPrice {
    .sellingPriceLeft {
      width: 20%;
      color: #a7a7a7;
      font-size: 14px;
    }
    .sellingPriceRight {
      width: 60%;
      .sellingPriceRightBox {
        @include publicFlex;
        border: none;
        background-color: #f6f6f6;
        padding: 10px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 45px;
        .sellingPriceRightUnit {
          margin: 0;
          padding: 0;
          font-size: 14px;
          color: #000000;
          width: 10%;
        }
        .sellingPriceRightInput {
          margin: 0;
          padding: 0;
          border: none;
          background-color: #f6f6f6;
          width: 90%;
        }
      }
      .sellingPriceRightNum {
        font-size: 14px;
        color: #000000;
      }
    }
  }
}
</style>
